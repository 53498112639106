import { Typography } from 'antd'
import React from 'react'
const { Text } = Typography

const WhatIsATicketPortion = () => (
  <Text>
    <Text style={{ display: 'block', marginBottom: '20px' }}>
      Transza losów to jeden konkretny przedział w którym Lotto przewidziało
      konkretną liczbę nagród. Podczas rozpoczęcia sprzedaży do sprzedaży Lotto
      może wypuścić wiecej niż jedną transzę konkretnych losów. Wielkość transzy
      i przypadające jej nagrody są określone w regulaminach dostępnych na
      stronach Lotto.
    </Text>
    <Text strong style={{ display: 'block' }}>
      Przykładowe transze losów:
    </Text>
    <Text strong style={{ display: 'block' }}>
      - Za 1 zł -
    </Text>
    <Text style={{ display: 'block', marginBottom: '20px' }}>
      <Text strong>- Lotek - </Text> 1 transza losów to 5 000 000 sztuk, w
      każdej transzy przewidziano 3 główne wygrane. Obecnie (27 stycznia 2022) w
      sprzedaży znajduje się edycja 603, która posiada nakład 40 000 000 losów.
      Czyli została wydana w 8 transzach z 24 nagrodami głównymi. Regulamin
      dostępny jest{' '}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.lotto.pl/storage/files/2021/10/29/0a23aef3-f7f6-4234-98d0-3dc53a40dc5f/Regulamin_zdrapka_Lotek_0603.pdf"
      >
        tutaj
      </a>
      .
    </Text>

    <Text strong style={{ display: 'block' }}>
      - Za 5 zł -
    </Text>
    <Text style={{ display: 'block', marginBottom: '20px' }}>
      <Text strong>- Ekstra Pensja! - </Text> 1 transza losów to 1 200 000
      sztuk, w każdej transzy przewidziano 1 główną wygraną. Obecnie (27
      stycznia 2022) w sprzedaży znajduje się edycja 612, która posiada nakład
      12 000 000 losów. Czyli została wydana w 10 transzach z 10 nagrodami
      głównymi. Regulamin dostępny jest{' '}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.lotto.pl/storage/files/2022/1/10/716bf1c3-7dcb-4824-bd1e-ae5da6df2976/Regulamin_0612.pdf"
      >
        tutaj
      </a>
      .
    </Text>

    <Text strong style={{ display: 'block' }}>
      - Za 10 zł -
    </Text>
    <Text style={{ display: 'block', marginBottom: '20px' }}>
      <Text strong>- Mega Pensja - </Text> 1 transza losów to 2 000 000 sztuk, w
      każdej transzy przewidziano 1 główną wygraną. Obecnie (27 stycznia 2022) w
      sprzedaży znajduje się edycja 577, która posiada nakład 6 000 000 losów.
      Czyli została wydana w 3 transzach z 3 nagrodami głównymi. Regulamin
      dostępny jest{' '}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.lotto.pl/storage/files/2021/3/23/28b73f99-de0e-49c5-ad47-c483383514e7/REGULAMIN_MEGA%20PENSJA_0577.pdf"
      >
        tutaj
      </a>
      .
    </Text>

    <Text strong style={{ display: 'block' }}>
      - Za 20 zł -
    </Text>
    <Text style={{ display: 'block', marginBottom: '20px' }}>
      <Text strong>- Droga do miliona - </Text> 1 transza losów to 2 001 000
      sztuk, w każdej transzy przewidziano 1 główną wygraną. Obecnie (27
      stycznia 2022) w sprzedaży znajduje się edycja 598, która posiada nakład 2
      001 000 losów. Czyli została wydana w 1 transzy z 1 nagrodą główną.
      Regulamin dostępny jest{' '}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.lotto.pl/storage/files/2021/9/17/eb663c18-4da9-43c9-b910-ee307dc1de40/REGULAMIN%20DROGA%20DO%20MILIONA%200598_2021.pdf"
      >
        tutaj
      </a>
      .
    </Text>

    <Text strong style={{ display: 'block' }}>
      Itd...
    </Text>
  </Text>
)

export default WhatIsATicketPortion
