import {
  HeartTwoTone,
  LineChartOutlined,
  MailOutlined,
  QuestionOutlined,
} from '@ant-design/icons'
import { Menu } from 'antd'
import React, { useMemo } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import styled from 'styled-components'

const StyledMenu = styled(Menu)`
  .ant-menu-item-active {
    background-color: #1890ff !important;
  }
`

const MyHeader = () => {
  const location = useLocation()

  const menuItems = useMemo(
    () => [
      {
        key: '/',
        label: (
          <NavLink to="/">
            <LineChartOutlined /> Zdrapki
          </NavLink>
        ),
      },
      {
        key: '/faq',
        label: (
          <NavLink to="/faq">
            <QuestionOutlined /> FAQ
          </NavLink>
        ),
      },
      {
        key: '/wspieram',
        label: (
          <NavLink to="/wspieram">
            <HeartTwoTone twoToneColor="#eb2f96" /> Wsparcie
          </NavLink>
        ),
      },
      {
        key: '/kontakt',
        label: (
          <NavLink to="/kontakt">
            <MailOutlined /> Kontakt
          </NavLink>
        ),
      },
    ],
    [],
  )

  return (
    <StyledMenu
      theme="dark"
      mode="horizontal"
      items={menuItems}
      defaultSelectedKeys={[location.pathname]}
      style={{ lineHeight: '64px' }}
    />
  )
}

export default MyHeader
