import { Alert, Button, DatePicker, Form, Input, InputNumber } from 'antd'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import AdminApi from '../../../api/adminApi'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
}

const StyledDataPicker = styled(DatePicker)`
  width: 200px;
  margin-left: auto;
  margin-right: auto;
`

const HistoricalData = ({ ticketId }) => {
  const [ticketHistory, setTicketHistory] = useState(null)
  const [ticketHistoryResponse, setTicketHistoryResponse] = useState(null)
  const [ticketDateChangeResponse, setTicketDateChangeResponse] = useState(null)
  const [historyFormIsSaving, setHistoryFormIsSaving] = useState(false)
  const [dataDownloadInProgress, setDataDownloadInProgress] = useState(false)

  const getTicketHistory = useCallback(
    async (dateFilter) => {
      const data = await AdminApi.getTicketHistory(ticketId, dateFilter)
      setTicketHistory(data)
    },
    [ticketId],
  )

  useEffect(() => {
    getTicketHistory()
  }, [getTicketHistory])

  const handleHistoryFormSubmit = async () => {
    setTicketHistoryResponse(null)
    setHistoryFormIsSaving(true)
    try {
      await AdminApi.updateTicketHistory(ticketHistory)
      setTicketHistoryResponse({ success: true })
      await getTicketHistory({ dateFilter: ticketHistory.date })
    } catch (e) {
      setTicketHistoryResponse({
        success: false,
        message: 'Nieznany błąd',
      })
    }
    setHistoryFormIsSaving(false)
  }

  const handleDownloadDataForDifferentDay = async () => {
    setTicketHistoryResponse(null)
    setDataDownloadInProgress(true)
    try {
      await getTicketHistory({ dateFilter: ticketHistory.date })
      setTicketDateChangeResponse({ success: true })
    } catch (e) {
      setTicketDateChangeResponse({
        success: false,
        message: 'Nieznany błąd',
      })
    }
    setDataDownloadInProgress(false)
  }

  const handleHistoryChangeCustomInput = (name, val) => {
    setTicketHistory((prevTicketHistory) => ({
      ...prevTicketHistory,
      [name]: val,
    }))
  }

  if (!ticketHistory) return <h1> Loading</h1>

  return (
    <Form {...formItemLayout}>
      <Form.Item label="Id rekordu">
        <Input id="id" value={ticketHistory.id} disabled />
      </Form.Item>
      <Form.Item label="Id zdrapki">
        <Input id="ticketId" value={ticketHistory.ticketId} disabled />
      </Form.Item>
      <Form.Item label="Data">
        <StyledDataPicker
          allowClear={false}
          value={dayjs(ticketHistory.date)}
          onChange={(val) =>
            handleHistoryChangeCustomInput('date', val.toString())
          }
        />
        <Button
          type="primary"
          loading={dataDownloadInProgress}
          onClick={handleDownloadDataForDifferentDay}
          style={{ marginTop: '15px' }}
        >
          Pobierz dane z wybranego dnia
        </Button>
        {ticketDateChangeResponse && (
          <Alert
            message={
              ticketDateChangeResponse.success
                ? 'Pomyślnie pobrano dane'
                : 'Wystąpił błąd podczas pobierania!'
            }
            description={ticketDateChangeResponse.message}
            type={ticketDateChangeResponse.success ? 'success' : 'error'}
            closable
          />
        )}
      </Form.Item>
      <Form.Item label="Pula na wygrane">
        <InputNumber
          style={{ width: 160 }}
          min={100000}
          max={100000000}
          value={ticketHistory.prizePool}
          onChange={(val) => handleHistoryChangeCustomInput('prizePool', val)}
        />
      </Form.Item>
      <Form.Item label="Popularność">
        <InputNumber
          min={0}
          style={{ width: 160 }}
          max={10000000}
          value={ticketHistory.popularity}
          onChange={(val) => handleHistoryChangeCustomInput('popularity', val)}
        />
      </Form.Item>
      <Form.Item label="Prawdopodobieństwo wygranej I stopnia">
        <InputNumber
          min={0}
          max={1}
          step={0.00001}
          style={{ width: 160 }}
          value={ticketHistory.chanceForWinning1stDegreePrize}
          onChange={(val) =>
            handleHistoryChangeCustomInput(
              'chanceForWinning1stDegreePrize',
              val,
            )
          }
        />
      </Form.Item>
      <Form.Item label="Szacowany zwrot">
        <InputNumber
          min={-60}
          max={100}
          style={{ width: 160 }}
          value={ticketHistory.investmentReturn}
          onChange={(val) =>
            handleHistoryChangeCustomInput('investmentReturn', val)
          }
        />
      </Form.Item>
      <Form.Item label="Szacowana pozostała liczba zdrapek">
        <InputNumber
          min={10000}
          max={50000000}
          style={{ width: 160 }}
          value={ticketHistory.estimatedTicketsLeft}
          onChange={(val) =>
            handleHistoryChangeCustomInput('estimatedTicketsLeft', val)
          }
        />
        <Button
          type="primary"
          loading={historyFormIsSaving}
          onClick={handleHistoryFormSubmit}
          style={{ display: 'block', marginTop: '15px' }}
        >
          Zapisz zmiany
        </Button>
        {ticketHistoryResponse && (
          <Alert
            message={
              ticketHistoryResponse.success
                ? 'Zaktualizowano pomyślnie'
                : 'Wystąpił błąd!'
            }
            description={ticketHistoryResponse.message}
            type={ticketHistoryResponse.success ? 'success' : 'error'}
            closable
          />
        )}
      </Form.Item>
    </Form>
  )
}

HistoricalData.propTypes = {
  ticketId: PropTypes.number.isRequired,
}

export default HistoricalData
