import { Typography } from 'antd'
import React from 'react'
import { Helmet } from 'react-helmet-async'
import FormSpreeForm from '../contact/Form'

const { Title, Text } = Typography

const ContactPage = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Kontakt - Jaka Zdrapka</title>
        <script
          src="https://www.google.com/recaptcha/api.js"
          async
          defer
        ></script>
      </Helmet>
      <Title style={{ fontSize: '20px' }}>Kontakt</Title>
      <Text style={{ display: 'block', marginTop: '15px' }}>
        Jeżeli chcesz się ze mną skontaktować skorzystaj z poniższego formularza
      </Text>
      <FormSpreeForm />
    </>
  )
}

export default ContactPage
