import { Typography } from 'antd'
import React from 'react'
import HowTicketsArePackedTable from './HowTicketsArePackedTable'
const { Text } = Typography

const HowTicketsArePacked = () => (
  <Text>
    <Text style={{ display: 'block', marginBottom: '10px' }}>
      Zdrapki pakowane są w paczki, a ilość zdrapek w paczce zależy głównie od
      ceny zdrapki. Typowe paczki zdrapek są pakowane jak w tabeli poniżej.
    </Text>
    <Text strong>Wyjątki:</Text>
    <Text style={{ display: 'block' }}>
      - Zdrapki{' '}
      <Text strong>&quot;Podwójna Gra&quot;, &quot;Kasa Kasa&quot;</Text>{' '}
      kosztują 5 zł, ale są pakowane w paczki po 20 sztuk. Wynika to z tego, że
      są one grubsze niż inne. Powodem pogrubienia jest to, że z tyłu zdrapek
      umieszczono dodatkowe odrywane okienka z możliwą wygraną.
    </Text>
    <Text style={{ display: 'block' }}>
      - Zdrapka <Text strong>&quot;Playbook 7&quot;</Text> kosztuje 30 zł, ale
      jest pakowana w paczki po 20 sztuk. Wynika to z tego, że zdrapka jest
      sprzedawana w formie wielostronicowej książeczki, a nie pojedynczej
      tektury, jak większość zdrapek.
    </Text>
    <Text style={{ display: 'block' }}>
      - Zdrapka <Text strong>&quot;Pełny Portfel&quot;</Text> kosztuje 30 zł,
      ale jest pakowana w paczki po 25 sztuk. Wynika to z tego, że zdrapka jest
      sprzedawana w formie rozrywanej książeczki, a nie pojedynczej tektury, jak
      większość zdrapek.
    </Text>
    <HowTicketsArePackedTable />
  </Text>
)

export default HowTicketsArePacked
