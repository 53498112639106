import { Alert, Button, Input, Table } from 'antd'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import AdminApi from '../../../api/adminApi'

const StyledTable = styled(Table)`
  width: 600px;
  display: block;
  margin-left: auto;
  margin-right: auto;
`

const StyledButton = styled(Button)`
  display: block;
  margin-left: auto;
  margin-right: auto;
`

const InitialPrizesData = ({ ticketId }) => {
  const [ticketInitialPrizes, setTicketInitialPrizes] = useState(null)
  const [initialPrizesAreSaving, setInitialPrizesAreSaving] = useState(false)
  const [initialPrizesChangeResponse, setInitialPrizesChangeResponse] =
    useState(null)

  const getTicketInitialPrizes = useCallback(async () => {
    const data = await AdminApi.getTicketInitialPrizes(ticketId)
    setTicketInitialPrizes(data)
  }, [ticketId])

  useEffect(() => {
    getTicketInitialPrizes()
  }, [getTicketInitialPrizes])

  const handleInitialPrizesChangeSubmit = async () => {
    setInitialPrizesAreSaving(true)
    try {
      await AdminApi.updateTicketInitialPrizes(ticketInitialPrizes)
      setInitialPrizesChangeResponse({ success: true })
      await getTicketInitialPrizes()
    } catch (e) {
      setInitialPrizesChangeResponse({
        success: false,
        message: 'Nieznany błąd',
      })
    }
    setInitialPrizesAreSaving(false)
  }

  const handleChange = useCallback((e) => {
    const currentId = parseInt(
      e.target.closest('tr').attributes['data-row-key'].value,
      10,
    )
    const { name, value } = e.target

    setTicketInitialPrizes((prevState) =>
      prevState.map((prize) =>
        prize.id === currentId
          ? { ...prize, [name]: parseInt(value, 10) }
          : prize,
      ),
    )
  }, [])

  const initialPrizesColumns = useMemo(
    () => [
      {
        title: 'Id rekordu',
        dataIndex: 'id',
        align: 'center',
        width: 150,
        key: 'id',
        render: (text) => <Input name="id" value={text} disabled />,
      },
      {
        title: 'Wysokość wygranej',
        dataIndex: 'value',
        align: 'center',
        width: 300,
        key: 'value',
        render: (text) => (
          <Input
            name="value"
            type="number"
            min={1}
            max={10000000}
            value={text}
            onChange={handleChange}
          />
        ),
      },
      {
        title: 'Ilość wygranych',
        dataIndex: 'amount',
        align: 'center',
        width: 150,
        key: 'amount',
        render: (text) => (
          <Input
            name="amount"
            type="number"
            min={1}
            max={35000000}
            value={text}
            onChange={handleChange}
          />
        ),
      },
    ],
    [handleChange],
  )

  if (!ticketInitialPrizes) return <h1>Loading...</h1>

  return (
    <>
      <StyledTable
        columns={initialPrizesColumns}
        dataSource={ticketInitialPrizes}
        rowKey="id"
        pagination={false}
      />
      <StyledButton
        type="primary"
        onClick={handleInitialPrizesChangeSubmit}
        loading={initialPrizesAreSaving}
        style={{ marginTop: '15px' }}
      >
        Zapisz zmiany
      </StyledButton>
      {initialPrizesChangeResponse && (
        <Alert
          message={
            initialPrizesChangeResponse.success
              ? 'Zaktualizowano pomyślnie'
              : 'Wystąpił błąd!'
          }
          description={initialPrizesChangeResponse.message}
          type={initialPrizesChangeResponse.success ? 'success' : 'error'}
          closable
        />
      )}
    </>
  )
}

InitialPrizesData.propTypes = {
  ticketId: PropTypes.number.isRequired,
}

export default InitialPrizesData
