import { Typography } from 'antd'
import React from 'react'
import { Helmet } from 'react-helmet-async'
import styled from 'styled-components'

const { Title, Text } = Typography

const StyledText = styled(Text)`
  display: block;
  margin-top: 15px;
  margin-bottom: 15px;
`

const StyledImage = styled.img`
  width: 300px;
`

const BecomeAPatronPage = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Wsparcie - Jaka Zdrapka</title>
        <meta
          name="description"
          content="Zastanawiasz się jak docenić moją pracę? Kup mi kawę :)!"
        />
      </Helmet>
      <Title style={{ fontSize: '20px' }}>Wsparcie</Title>
      <StyledText>
        Koszt utrzymania całego serwisu to około 25$ miesięcznie. Obecnie
        wszystkie te koszty wziąłem na siebie. <br />
        Na stronie nie znajdziesz reklam, nikt z Lotto mi nie płaci a wszystkie
        dane udostępniane są za darmo. <br />
        Dlatego jeżeli chcesz docenić moją pracę to zabierz mnie na wirtualną
        kawę! :)
        <br />
      </StyledText>
      <a
        href="https://buycoffee.to/jakazdrapka"
        target="_blank"
        rel="noopener noreferrer"
      >
        <StyledImage
          src="https://buycoffee.to/btn/buycoffeeto-btn-primary.svg"
          alt="Postaw mi kawę na buycoffee.to"
        />
      </a>
    </>
  )
}

export default BecomeAPatronPage
