import { LineChartOutlined } from '@ant-design/icons'
import { DatePicker, Space, Spin, Typography } from 'antd'
import dayjs from 'dayjs'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import TicketsApi from '../../api/ticketApi'
import TicketChanceForWinning1stDegreePrize from './ticketStatistics/TicketChanceForWinning1stDegreePrize'
import TicketEstimatedTicketsLeft from './ticketStatistics/TicketEstimatedTicketsLeft'
import TicketInvestmentReturn from './ticketStatistics/TicketInvestmentReturn'
import TicketPrizePool from './ticketStatistics/TicketPrizePool'
import TicketTopPrizesLeft from './ticketStatistics/TicketTopPrizesLeft'

const { Title } = Typography
const { RangePicker } = DatePicker

const TicketStatisticsPage = () => {
  const ticketGameNumber = useMemo(
    () => window.location.href.substr(window.location.href.length - 3),
    [],
  )

  const [diagramData, setDiagramData] = useState(null)
  const [datesRequest, setDatesRequest] = useState(null)

  const getDiagramData = useCallback(async () => {
    setDiagramData(
      await TicketsApi.getPrizePoolHistory(ticketGameNumber, datesRequest),
    )
  }, [ticketGameNumber, datesRequest])

  const handleRangePickerChange = useCallback((dates) => {
    setDatesRequest({
      statisticsStartDate: dates[0].format('YYYY-MM-DD'),
      statisticsEndDate: dates[1].format('YYYY-MM-DD'),
    })
  }, [])

  useEffect(() => {
    getDiagramData()
  }, [getDiagramData])

  if (!diagramData)
    return (
      <>
        <Title
          level={2}
          strong
          style={{
            textAlign: 'center',
            display: 'block',
            marginBottom: '15px',
          }}
        >
          Pobieranie informacji o szczegółach zdrapki...
          <br />
          <Spin size="large" />
        </Title>
      </>
    )

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {diagramData.ticketName} - dane historyczne zdrapki - Jaka Zdrapka
        </title>
        <meta
          name="description"
          content={`Jaka jest pula na wygrane i jakie są szanse na wygranie nagrody głównej w zdrapce ${diagramData.ticketName}? `}
        />
      </Helmet>
      <Title style={{ display: 'block', marginTop: '20px', fontSize: '20px' }}>
        {diagramData.ticketName} [{ticketGameNumber}] dostępne dane:
      </Title>
      <Title style={{ display: 'block', marginTop: '20px', fontSize: '18px' }}>
        Przedział czasowy: &nbsp;
        <Space>
          <RangePicker
            allowClear={false}
            allowEmpty={[false, false]}
            value={[
              dayjs(diagramData.statisticsStartDate),
              dayjs(diagramData.statisticsEndDate),
            ]}
            onChange={handleRangePickerChange}
            style={{ minHeight: '30px', padding: '0px 5px' }}
          />
        </Space>
      </Title>
      <Title style={{ fontSize: '18px' }}>
        <LineChartOutlined />
        &nbsp;Stan puli przeznaczonej na wygrane:
      </Title>
      <TicketPrizePool
        diagramData={diagramData.data}
        initialPrizePool={diagramData.initialPrizePool}
      />
      <Title style={{ fontSize: '18px' }}>
        <LineChartOutlined />
        &nbsp;Szansa na wygranie nagrody 1. stopnia, przy zakupie 1 sztuki:
      </Title>
      <TicketChanceForWinning1stDegreePrize
        diagramData={diagramData.data}
        biggestChancesFor1stDegreePrize={
          diagramData.biggestChancesFor1stDegreePrize
        }
      />
      <Title style={{ fontSize: '18px' }}>
        <LineChartOutlined />
        &nbsp;Szacowany zwrot przy zakupie pozostałych losów z transzy:
      </Title>
      <TicketInvestmentReturn diagramData={diagramData.data} />
      <Title style={{ fontSize: '18px' }}>
        <LineChartOutlined />
        &nbsp;Szacowana ilość zdrapek dostępnych w sprzedaży:
      </Title>
      <TicketEstimatedTicketsLeft
        diagramData={diagramData.data}
        initialQuantity={diagramData.initialTicketsQuantity}
      />
      <Title style={{ fontSize: '18px' }}>
        <LineChartOutlined />
        &nbsp;Pozostała ilość głównych nagród:
      </Title>

      <TicketTopPrizesLeft
        diagramData={diagramData.data}
        initialTopPrizes={diagramData.initialTopPrizes}
      />
    </>
  )
}

export default TicketStatisticsPage
