import { Checkbox, Form, Input, Radio, Typography } from 'antd'
import React, { useMemo } from 'react'
import styled from 'styled-components'

const { Title, Text } = Typography

const StyledTitle = styled(Title)`
  font-size: 20px;
`

const StyledInput = styled(Input)`
  max-width: 250px;
  height: 30px;
`

const TicketFilters = ({ handleFilterChange, request }) => {
  const priceOptions = useMemo(
    () => [
      { value: '', label: 'Wszystkie' },
      { value: 1, label: '1 zł' },
      { value: 2, label: '2 zł' },
      { value: 3, label: '3 zł' },
      { value: 5, label: '5 zł' },
      { value: 10, label: '10 zł' },
      { value: 20, label: '20 zł' },
      { value: 30, label: '30 zł' },
    ],
    [],
  )

  return (
    <Form>
      <Form.Item style={{ marginBottom: '5px' }}>
        <StyledTitle>Pokazuj zdrapki w cenie:</StyledTitle>
        <Radio.Group
          value={request.price}
          onChange={(e) => handleFilterChange('price', e.target.value)}
        >
          {priceOptions.map((option) => (
            <Radio.Button key={option.value} value={option.value}>
              {option.label}
            </Radio.Button>
          ))}
        </Radio.Group>
      </Form.Item>
      <Form.Item style={{ marginBottom: '5px' }}>
        <StyledInput
          placeholder="Nazwa zdrapki"
          value={request.name}
          onChange={(e) => handleFilterChange('name', e.target.value)}
        />
      </Form.Item>
      <Form.Item style={{ marginBottom: '5px' }}>
        <Text strong>Ukryj zdrapki bez nagród głównych </Text>
        <Checkbox
          checked={request.hideWithoutMainPrize}
          onChange={(e) =>
            handleFilterChange('hideWithoutMainPrize', e.target.checked)
          }
        ></Checkbox>
      </Form.Item>
    </Form>
  )
}

export default TicketFilters
