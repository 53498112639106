import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { formatPrice } from '../../common/Helpers'

const TicketPrizePool = ({ diagramData, initialPrizePool }) => {
  const yDomain = useMemo(
    () => [0, initialPrizePool + initialPrizePool * 0.1],
    [initialPrizePool],
  )

  const tickFormatter = useMemo(
    () => (tick) => new Date(tick).toLocaleDateString(),
    [],
  )
  const yTickFormatter = useMemo(() => (tick) => formatPrice(tick), [])
  const tooltipFormatter = useMemo(() => (value) => formatPrice(value), [])
  const labelFormatter = useMemo(
    () => (date) => 'Data: ' + new Date(date).toLocaleDateString(),
    [],
  )

  return (
    <ResponsiveContainer height={500}>
      <LineChart data={diagramData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" tickFormatter={tickFormatter} />
        <YAxis
          dataKey="prizePool"
          width={105}
          domain={yDomain}
          tickFormatter={yTickFormatter}
        />
        <Tooltip formatter={tooltipFormatter} labelFormatter={labelFormatter} />
        <Legend />
        <Line
          type="monotone"
          dataKey="prizePool"
          stroke="#8884d8"
          activeDot={{ r: 8 }}
          name="Pula na wygrane"
        />
        <ReferenceLine
          y={initialPrizePool}
          stroke="green"
          strokeDasharray="3 3"
          label={{
            position: 'top',
            value:
              'Początkowa pula na wygrane = ' + formatPrice(initialPrizePool),
            fill: 'green',
            fontSize: 14,
          }}
        />
      </LineChart>
    </ResponsiveContainer>
  )
}

TicketPrizePool.propTypes = {
  diagramData: PropTypes.array.isRequired,
  initialPrizePool: PropTypes.number.isRequired,
}

export default TicketPrizePool
