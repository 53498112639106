import { Tag } from 'antd'
import dayjs from 'dayjs'

export const renderColoredValue = (value) => {
  let color
  switch (true) {
    case value < -40:
      color = 'red'
      break
    case value < -30:
      color = 'volcano'
      break
    case value < -20:
      color = 'orange'
      break
    case value < -10:
      color = 'gold'
      break
    case value <= 0:
      color = 'lime'
      break
    default:
      color = 'green'
  }
  return <Tag color={color}>{value}%</Tag>
}

export const restOfTicketsToPolishString = (value) => {
  if (value > 4 || value === 0) return 'Pozostało'
  if (value === 1) return 'Pozostała'
  return 'Pozostały'
}

export const formatPrice = (nStr) => {
  return `${formatNumber(nStr)} zł`
}

export const formatNumber = (nStr) => {
  return nStr.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}

export const formatPercents = (nStr) => {
  return `${formatNumber(nStr)}%`
}

const createSlug = (ticketName, ticketGameNumber) => {
  return `${ticketName} ${ticketGameNumber}`
    .replace(/[^a-z0-9_]+/gi, '-')
    .replace(/^-|-$/g, '')
    .toLowerCase()
}

export const createTicketLink = (ticketName, ticketGameNumber) => {
  return `/zdrapka/${createSlug(ticketName, ticketGameNumber)}`
}

export const createTicketLinkInDetails = (ticketName, ticketGameNumber) => {
  return createSlug(ticketName, ticketGameNumber)
}

export const createDiagramLink = (ticketName, ticketGameNumber) => {
  return `/zdrapka/wykresy/${createSlug(ticketName, ticketGameNumber)}`
}

export const createDangerousHTML = (comment) => {
  return { __html: comment }
}

export const prepareTicketComment = (
  comment,
  startDate,
  endDate,
  topPrizesLeft,
  initialTopPrizes,
  winningTicketsScans,
) => {
  let tempComment = comment
  const today = dayjs().startOf('day')

  if (
    topPrizesLeft == null ||
    initialTopPrizes == null ||
    winningTicketsScans == null
  ) {
    return { __html: tempComment }
  }

  const missingPrizes = initialTopPrizes - (winningTicketsScans + topPrizesLeft)
  if (missingPrizes > 0) {
    tempComment += `<p><strong>Brak informacji o ${missingPrizes} nagrodzie/nagrodach głównych!</strong></p>`
  }

  if (startDate && dayjs(startDate).isAfter(today)) {
    tempComment += `<p><strong style="color: rgb(230, 0, 0);">ROZPOCZĘCIE SPRZEDAŻY ${prepareTicketDate(dayjs(startDate).format('DD MM YYYY'))}</strong></p>`
  }

  if (endDate) {
    tempComment += `<p><strong style="color: rgb(230, 0, 0);">OSTATNI DZIEŃ SPRZEDAŻY ${prepareTicketDate(dayjs(endDate).format('DD MM YYYY'))}</strong></p>`
  }

  return { __html: tempComment }
}

export const saveToken = (token) => {
  localStorage.setItem('token', token)
}

export const getToken = () => {
  return localStorage.getItem('token')
}

export const prepareTicketDate = (date) => {
  const [day, month, year] = date.split(' ')
  const monthNames = [
    'STYCZNIA',
    'LUTEGO',
    'MARCA',
    'KWIETNIA',
    'MAJA',
    'CZERWCA',
    'LIPCA',
    'SIERPNIA',
    'WRZEŚNIA',
    'PAŹDZIERNIKA',
    'LISTOPADA',
    'GRUDNIA',
  ]

  return `${parseInt(day)} ${monthNames[parseInt(month) - 1]} ${year}`
}
