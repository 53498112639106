import { Typography } from 'antd'
import React from 'react'

const { Text } = Typography

const InfoBox = ({ status }) => {
  if (!status) return null

  if (status === 'SUCCESS')
    return <Text strong>Dzięki! Odpowiem najszybciej jak to możliwe :)</Text>

  return <Text strong>Ups! Coś poszło nie tak. Spróbuj ponownie później.</Text>
}

export default InfoBox
