import { Collapse, Spin } from 'antd'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import AdminApi from '../../api/adminApi'
import BasicData from '../admin/ticketDetails/BasicData'
import HistoricalData from '../admin/ticketDetails/HistoricalData'
import InitialPrizesData from '../admin/ticketDetails/InitialPrizesData'
import HistoricalPrizesData from './ticketDetails/HistoricalPrizesData'

const { Panel } = Collapse

const TicketDetailsPage = () => {
  const ticketGameNumber = window.location.href.substr(
    window.location.href.length - 3,
  )
  const [ticket, setTicket] = useState(null)

  const getTicketDetails = useCallback(async () => {
    const data = await AdminApi.getTicketByNumber(ticketGameNumber)
    setTicket(data)
  }, [ticketGameNumber])

  useEffect(() => {
    getTicketDetails()
  }, [getTicketDetails])

  const helmetData = useMemo(() => {
    if (!ticket) return null
    return {
      title: `${ticket.name} - szczegóły zdrapki - Jaka Zdrapka`,
      description: `Edycja szczegółów zdrapki ${ticket.name}`,
    }
  }, [ticket])

  if (!ticket)
    return (
      <div style={{ textAlign: 'center', padding: '20px' }}>
        <Spin size="large" />
        <h1>Loading</h1>
      </div>
    )

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        {helmetData && (
          <>
            <title>{helmetData.title}</title>
            <meta name="description" content={helmetData.description} />
          </>
        )}
      </Helmet>
      <h2>Edycja danych dla zdrapki: {ticket.name}:</h2>
      <Collapse accordion>
        <Panel header="Panel edycji danych podstawowych" key="1">
          <BasicData />
        </Panel>
        <Panel header="Panel edycji danych historycznych" key="2">
          <HistoricalData ticketId={ticket.id} />
        </Panel>
        <Panel header="Panel edycji historycznych nagród" key="4">
          <HistoricalPrizesData ticketId={ticket.id} />
        </Panel>
        <Panel header="Panel edycji początkowych nagród głównych" key="5">
          <InitialPrizesData ticketId={ticket.id} />
        </Panel>
      </Collapse>
    </>
  )
}

export default TicketDetailsPage
