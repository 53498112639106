import { Alert, Button, DatePicker, Input, Table } from 'antd'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import AdminApi from '../../../api/adminApi'
import { formatPrice } from '../../common/Helpers'

const StyledTable = styled(Table)`
  width: 700px;
  display: block;
  margin-left: auto;
  margin-right: auto;
`
const StyledDataPicker = styled(DatePicker)`
  width: 200px;
  margin-left: auto;
  margin-right: auto;
`

const StyledButton = styled(Button)`
  display: block;
  margin-left: auto;
  margin-right: auto;
`

const HistoricalPrizesData = ({ ticketId }) => {
  const [serverResponse, setServerResponse] = useState(null)
  const [historicalPrizesAreSaving, setHistoricalPrizesAreSaving] =
    useState(false)
  const [historicalPrizesChangeResponse, setHistoricalPrizesChangeResponse] =
    useState(null)
  const [
    dataDownloadForDifferentDayInProgress,
    setDataDownloadForDifferentDayInProgress,
  ] = useState(false)
  const [ticketDateChangeResponse, setTicketDateChangeResponse] = useState(null)

  const getTicketHistoricalPrizes = useCallback(
    async (dateFilter) => {
      setServerResponse(
        await AdminApi.getTicketHistoricalPrizes(ticketId, dateFilter),
      )
    },
    [ticketId],
  )

  useEffect(() => {
    getTicketHistoricalPrizes()
  }, [getTicketHistoricalPrizes])

  const handleHistoricalPrizesChangeSubmit = async () => {
    setServerResponse(null)
    setHistoricalPrizesAreSaving(true)
    try {
      await AdminApi.updateTicketHistoricalPrizes(
        serverResponse.historicalPrizes,
      )
      setHistoricalPrizesChangeResponse({ success: true })
      await getTicketHistoricalPrizes({ dateFilter: serverResponse.date })
    } catch (e) {
      setHistoricalPrizesChangeResponse({
        success: false,
        message: 'Nieznany błąd',
      })
    }
    setHistoricalPrizesAreSaving(false)
  }

  const handleInputChange = (e) => {
    const currentId = e.target.closest('tr').attributes['data-row-key'].value

    const tempHistoricalPrizes = [...serverResponse.historicalPrizes]
    const prize = tempHistoricalPrizes.find((t) => t.id === parseInt(currentId))
    tempHistoricalPrizes[tempHistoricalPrizes.indexOf(prize)] = {
      ...prize,
      [e.target.name]: parseInt(e.target.value),
    }
    setServerResponse({
      ...serverResponse,
      historicalPrizes: tempHistoricalPrizes,
    })
  }

  const handleDownloadDataForDifferentDay = async () => {
    setServerResponse(null)
    setDataDownloadForDifferentDayInProgress(true)
    try {
      await getTicketHistoricalPrizes({ dateFilter: serverResponse.date })
      setTicketDateChangeResponse({ success: true })
    } catch (e) {
      setTicketDateChangeResponse({
        success: false,
        message: 'Nieznany błąd',
      })
    }
    setDataDownloadForDifferentDayInProgress(false)
  }

  const handleHistoryChangeCustomInput = (name, val) => {
    setServerResponse({ ...serverResponse, [name]: val })
  }

  const historicalPrizesColumns = [
    {
      title: 'Id rekordu',
      dataIndex: 'id',
      align: 'center',
      width: 150,
      key: 'id',
      // eslint-disable-next-line react/display-name
      render: (text) => <Input name="id" value={text} disabled />,
    },
    {
      title: 'Wysokość wygranej',
      dataIndex: 'value',
      align: 'center',
      width: 300,
      key: 'value',
      // eslint-disable-next-line react/display-name
      render: (text) => (
        <Input name="value" type="text" value={formatPrice(text)} disabled />
      ),
    },
    {
      title: 'Ilość wygranych',
      dataIndex: 'amount',
      align: 'center',
      width: 150,
      key: 'amount',
      // eslint-disable-next-line react/display-name
      render: (text) => (
        <Input
          name="amount"
          type="number"
          min={1}
          max={35000000}
          value={text}
          onChange={handleInputChange}
        />
      ),
    },
    {
      title: 'Id historii',
      dataIndex: 'ticketHistoryId',
      align: 'center',
      width: 150,
      key: 'historyId',
      // eslint-disable-next-line react/display-name
      render: (text) => <Input name="ticketHistoryId" value={text} disabled />,
    },
  ]

  if (!serverResponse) return <h1> Loading</h1>

  return (
    <>
      <div style={{ textAlign: 'center' }}>
        <StyledDataPicker
          allowClear={false}
          value={dayjs(serverResponse.date)}
          onChange={(val) => {
            handleHistoryChangeCustomInput('date', val.toString())
          }}
          style={{ marginRight: '15px' }}
        />
        <Button
          type="primary"
          loading={dataDownloadForDifferentDayInProgress}
          onClick={handleDownloadDataForDifferentDay}
        >
          Pobierz dane z danego dnia
        </Button>
      </div>
      {ticketDateChangeResponse && (
        <Alert
          message={
            ticketDateChangeResponse.success
              ? 'Pomyślnie pobrano dane'
              : 'Wystąpił błąd podczas pobierania!'
          }
          description={ticketDateChangeResponse.message}
          type={ticketDateChangeResponse.success ? 'success' : 'error'}
          closable
        />
      )}
      <StyledTable
        columns={historicalPrizesColumns}
        dataSource={serverResponse.historicalPrizes}
        rowKey={'id'}
        pagination={false}
      />
      <StyledButton
        type="primary"
        onClick={handleHistoricalPrizesChangeSubmit}
        loading={historicalPrizesAreSaving}
        style={{ display: 'block', marginTop: '15px' }}
      >
        Zapisz zmiany
      </StyledButton>
      {historicalPrizesChangeResponse && (
        <Alert
          message={
            historicalPrizesChangeResponse.success
              ? 'Zaktualizowano pomyślnie'
              : 'Wystąpił błąd!'
          }
          description={historicalPrizesChangeResponse.message}
          type={historicalPrizesChangeResponse.success ? 'success' : 'error'}
          closable
        />
      )}
    </>
  )
}

HistoricalPrizesData.propTypes = {
  ticketId: PropTypes.number.isRequired,
}

export default HistoricalPrizesData
