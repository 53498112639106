import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { Alert, Button, Form, Input, Typography } from 'antd'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import AdminApi from '../../api/adminApi'
import { saveToken } from '../common/Helpers'

const { Title } = Typography

const StyledTitle = styled(Title)`
  font-size: 18px;
`

const StyledInput = styled(Input)`
  max-width: 250px;
  min-height: 30px;
`

const LoginPage = () => {
  const navigate = useNavigate()
  const [error, setError] = useState(null)

  const handleSubmit = async (values) => {
    try {
      const response = await AdminApi.authorize(values)
      saveToken(response.token)
      navigate('/admin/zdrapki')
    } catch (e) {
      setError('Nieprawidłowy login lub hasło. Spróbuj ponownie.')
    }
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Logowanie do panelu Admina - Jaka Zdrapka</title>
        <meta
          name="description"
          content="Logowanie do panelu Admina - Jaka Zdrapka."
        />
      </Helmet>
      <StyledTitle>Logowanie do panelu Admina</StyledTitle>
      <Form
        onFinish={handleSubmit}
        className="login-form"
        colon={true}
        layout="horizontal"
      >
        <Form.Item
          name="username"
          rules={[{ required: true, message: 'Please input your username!' }]}
        >
          <StyledInput
            prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="Username"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Please input your Password!' }]}
        >
          <StyledInput
            prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            Zaloguj
          </Button>
        </Form.Item>
        {error && <Alert message={error} type="error" showIcon closable />}
      </Form>
    </>
  )
}

export default LoginPage
