import { Button, Form, Input } from 'antd'
import React, { useState } from 'react'
import styled from 'styled-components'
import wretch from 'wretch'
import InfoBox from './InfoBox'

const { TextArea } = Input
const action = 'https://formspree.io/xnqdopeq'

const StyledForm = styled(Form)`
  .contact-form {
    max-width: 500px;
  }
  .sent-message-form-button {
    float: left;
  }
  .g-recaptcha {
    margin-left: 20px;
  }
`

const StyledInput = styled(Input)`
  max-width: 500px;
  min-height: 30px;
`

const StyledFormItem = styled(Form.Item)`
  max-width: 500px;
`

const StyledFormItemDisplayBlock = styled(Form.Item)`
  max-width: 500px;
  display: inline-block;
`

const FormSpreeForm = () => {
  const [status, setStatus] = useState('')

  const submitForm = async (values) => {
    const formData = new FormData()
    formData.append('email', values.email)
    formData.append('message', values.message)
    formData.append(
      'g-recaptcha-response',
      document.getElementById('g-recaptcha-response').value,
    )

    try {
      const response = await wretch()
        .url(action)
        .options({ headers: { Accept: 'application/json' } })
        .body(formData)
        .post()
        .json()

      setStatus(response.ok ? 'SUCCESS' : 'ERROR')
    } catch (error) {
      setStatus('ERROR')
    }
  }

  return (
    <StyledForm onFinish={submitForm} layout="horizontal">
      <StyledFormItem
        name="email"
        rules={[
          { required: true, message: 'Please input your email address!' },
        ]}
      >
        <StyledInput type="email" placeholder="Twój E-mail" />
      </StyledFormItem>
      <StyledFormItem
        name="message"
        rules={[{ required: true, message: 'Please input your message!' }]}
      >
        <TextArea rows={6} placeholder="Wiadomość" />
      </StyledFormItem>
      <InfoBox status={status} />
      <StyledFormItemDisplayBlock>
        <Button type="primary" htmlType="submit">
          Wyślij
        </Button>
      </StyledFormItemDisplayBlock>
      <div
        style={{ display: 'inline-block' }}
        className="g-recaptcha"
        data-sitekey="6Lf-XdkUAAAAAOlmOfo0R4z8xk4qKYoMlni-eztc"
      ></div>
    </StyledForm>
  )
}

export default FormSpreeForm
