import wretch from 'wretch'
import QueryStringAddon from 'wretch/addons/queryString'
import { getToken } from '../components/common/Helpers'
import { prepareRequest } from './api'

const api = wretch()
  .errorType('json')
  .defer((w) => w.auth('Bearer ' + getToken()))
  .catcher(401, (e) => {
    window.location.pathname = '/admin/login'
  })
  .addon(QueryStringAddon)
  .url(process.env.REACT_APP_API_URL)

const AdminApi = {
  async getTicketsFromApi(request) {
    const modifiedRequest = prepareRequest(request)
    const result = await api
      .url('/admin/tickets')
      .query(modifiedRequest)
      .get()
      .json()
    return result
  },

  async getTicketByNumber(ticketNumber) {
    return await api.url(`/admin/ticket/${ticketNumber}`).get().json()
  },

  async getTicketHistory(ticketDbId, dateFilter) {
    return await api
      .url(`/admin/ticket/history/${ticketDbId}`)
      .query(dateFilter)
      .get()
      .json()
  },

  async getTicketInitialPrizes(ticketId) {
    return await api.url(`/admin/ticket/initial/${ticketId}`).get().json()
  },

  async getTicketHistoricalPrizes(ticketId, date) {
    return await api
      .url(`/admin/ticket/historicalprizes/${ticketId}`)
      .query(date)
      .get()
      .json()
  },

  async updateTicketInfo(ticket) {
    return await api.url(`/admin/ticket`).patch(ticket).res()
  },

  async updateTicketHistory(ticket) {
    return api.url(`/admin/ticket/history`).patch(ticket).res()
  },

  async updateTicketInitialPrizes(request) {
    return api.url(`/admin/ticket/initial`).patch(request).res()
  },

  async updateTicketHistoricalPrizes(request) {
    return api.url(`/admin/ticket/historical`).patch(request).res()
  },

  async updateTicketLastUpdateDate(request) {
    return api.url(`/admin/lastupdatedate`).patch(request).res()
  },

  async deleteTicketUpdateByDate(request) {
    return api
      .url(`/admin/ticketupdate/${request.date}`)
      .options({ headers: { password: request.password } })
      .delete()
      .res()
  },

  async authorize(request) {
    return api.url(`/admin/login`).post(request).json()
  },
}

export default AdminApi
