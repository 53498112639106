import { LockOutlined } from '@ant-design/icons'
import {
  Alert,
  Button,
  Checkbox,
  DatePicker,
  Input,
  Spin,
  Typography,
} from 'antd'
import dayjs from 'dayjs'
import React, { useCallback, useEffect, useState } from 'react'
import AdminApi from '../../api/adminApi'
import TicketsApi from '../../api/ticketApi'

const { Title } = Typography

const TicketBasePage = () => {
  const [lastUpdateDate, setLastUpdateDate] = useState(null)
  const [datePickerValue, setDatePickerValue] = useState(null)
  const [daletePickerValue, setDaletePickerValue] = useState(null)
  const [passwordInput, setPasswordInput] = useState('')
  const [formIsSaving, setFormIsSaving] = useState(false)
  const [deleteIsInProgress, setDeleteIsInProgress] = useState(false)
  const [response, setResponse] = useState(null)
  const [deleteHistoryResponse, setDeleteHistoryResponse] = useState(null)
  const [checkboxValue, setCheckboxValue] = useState(false)

  const getLastUpdateDate = useCallback(async () => {
    const data = await TicketsApi.getLastUpdateDate()
    setLastUpdateDate(data)
    setDatePickerValue(dayjs(data.lastUpdateDate))
  }, [])

  useEffect(() => {
    getLastUpdateDate()
  }, [getLastUpdateDate])

  const handleOnChangeDatePicker = (val) => {
    setDatePickerValue(dayjs(val))
  }

  const handleFormSubmit = async () => {
    setFormIsSaving(true)
    try {
      await AdminApi.updateTicketLastUpdateDate({
        lastUpdateDate: dayjs(datePickerValue).format('YYYY-MM-DD HH:mm:ss[Z]'),
        updateTicketsLastUpdateDate: checkboxValue,
      })
      setResponse({ success: true })
    } catch (e) {
      setResponse({
        success: false,
        message: e.json?.message || 'Wystąpił błąd',
      })
    }
    setFormIsSaving(false)
  }

  const handleHistoryDelete = async () => {
    setDeleteIsInProgress(true)
    try {
      await AdminApi.deleteTicketUpdateByDate({
        date: daletePickerValue.format(dayjs.HTML5_FMT.DATE),
        password: passwordInput,
      })
      setDeleteHistoryResponse({ success: true })
    } catch (e) {
      setDeleteHistoryResponse({
        success: false,
        message: 'Usuwanie historii nie powiodło się.',
      })
    }
    setDeleteIsInProgress(false)
  }

  const handlePasswordInput = (e) => {
    setPasswordInput(e.target.value)
  }

  const handleCheckboxValue = (e) => {
    setCheckboxValue(e.target.checked)
  }

  if (!lastUpdateDate) {
    return (
      <div style={{ textAlign: 'center', padding: '20px' }}>
        <Spin size="large" />
        <Title style={{ fontSize: '18px' }}>Trwa pobieranie danych...</Title>
      </div>
    )
  }

  return (
    <>
      <Title style={{ fontSize: '20px' }}>Edycja danych globalnych</Title>
      <Title style={{ fontSize: '18px' }}>Data ostatniej aktualizacji:</Title>
      <DatePicker
        allowClear={false}
        showTime
        value={datePickerValue}
        onChange={(val) => handleOnChangeDatePicker(val)}
        style={{ margin: '15px' }}
      />
      <Checkbox
        onChange={handleCheckboxValue}
        style={{ display: 'block', margin: '15px' }}
      >
        Zmień także datę aktualizacji aktywnych zdrapek
      </Checkbox>
      <Button
        type="primary"
        loading={formIsSaving}
        onClick={handleFormSubmit}
        style={{ margin: '15px' }}
      >
        Zapisz zmianę
      </Button>
      {response && (
        <Alert
          message={
            response.success ? 'Zaktualizowano pomyślnie' : 'Wystąpił błąd!'
          }
          description={response.message}
          type={response.success ? 'success' : 'error'}
          closable
        />
      )}
      <Title style={{ marginTop: '50px', fontSize: '18px' }}>
        Usuwanie danych historycznych:
      </Title>
      <DatePicker
        allowClear={false}
        value={daletePickerValue}
        onChange={(val) => setDaletePickerValue(dayjs(val))}
        style={{ margin: '15px' }}
      />
      <Input
        prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
        type="password"
        placeholder="Wpisz hasło"
        style={{ width: '174px', display: 'block', margin: '15px' }}
        value={passwordInput}
        onChange={handlePasswordInput}
      />
      <Button
        type="danger"
        loading={deleteIsInProgress}
        onClick={handleHistoryDelete}
        style={{ margin: '15px' }}
      >
        Usuń historię zdrapek z danego dnia
      </Button>
      {deleteHistoryResponse && (
        <Alert
          message={
            deleteHistoryResponse.success
              ? 'Usunięto pomyślnie'
              : 'Wystąpił błąd!'
          }
          description={deleteHistoryResponse.message}
          type={deleteHistoryResponse.success ? 'success' : 'error'}
          closable
        />
      )}
    </>
  )
}

export default TicketBasePage
