import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { formatPercents } from '../../common/Helpers'

const TicketInvestmentReturn = ({ diagramData }) => {
  const tickFormatter = useMemo(
    () => (tick) => new Date(tick).toLocaleDateString(),
    [],
  )
  const yTickFormatter = useMemo(() => (tick) => formatPercents(tick), [])
  const tooltipFormatter = useMemo(() => (value) => formatPercents(value), [])
  const labelFormatter = useMemo(
    () => (date) => 'Data: ' + new Date(date).toLocaleDateString(),
    [],
  )

  return (
    <ResponsiveContainer height={500}>
      <LineChart data={diagramData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" tickFormatter={tickFormatter} />
        <YAxis
          dataKey="investmentReturn"
          width={105}
          domain={[-50, 20]}
          tickFormatter={yTickFormatter}
        />
        <Tooltip formatter={tooltipFormatter} labelFormatter={labelFormatter} />
        <Legend />
        <Line
          type="monotone"
          dataKey="investmentReturn"
          stroke="#8884d8"
          activeDot={{ r: 8 }}
          name="Szacowany zwrot"
        />
        <ReferenceLine
          y={0}
          stroke="green"
          strokeDasharray="3 3"
          label={{
            position: 'top',
            value: 'Punkt opłacalności wykupienia wszystkich zdrapek',
            fill: 'green',
            fontSize: 14,
          }}
        />
      </LineChart>
    </ResponsiveContainer>
  )
}

TicketInvestmentReturn.propTypes = {
  diagramData: PropTypes.array.isRequired,
}

export default TicketInvestmentReturn
