import { Alert, Col, Table } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import AdminApi from '../../api/adminApi'
import {
  createDangerousHTML,
  createTicketLink,
  formatPrice,
} from '../common/Helpers'
import TicketFilters from '../common/TicketFilters'

const StyledTable = styled(Table)`
  table thead tr th {
    background: #f1f1f1 !important;
  }
  table thead tr th ant-table-column-has-sorters {
    background: #f0f0f0;
  }
  table tr:nth-child(even) {
    background: #fafafa;
  }
  th.column-comment,
  td.column-comment {
    text-align: center !important;
    padding: 15px 10px;
  }
`

const TicketsPage = () => {
  const [tickets, setTickets] = useState(null)
  const [isTableLoading, setIsTableLoading] = useState(true)
  const [error, setError] = useState(null)
  const [request, setRequest] = useState({
    currentPage: 1,
    pageSize: 10,
    price: '',
    name: '',
    hideWithoutMainPrize: false,
    sortDir: 'ascend',
    sortCol: 'name',
  })

  const getTickets = useCallback(async (request) => {
    setIsTableLoading(true)
    setError(null)

    try {
      const result = await AdminApi.getTicketsFromApi({
        ...request,
        currentPage: request.currentPage - 1,
      })
      setTickets(result)
    } catch (e) {
      setError(e.json)
    }
    setIsTableLoading(false)
  }, [])

  useEffect(() => {
    getTickets(request)
  }, [request, getTickets])

  const handleFilterChange = useCallback((filterName, value) => {
    setRequest((prevRequest) => ({
      ...prevRequest,
      currentPage: 1,
      [filterName]: value,
    }))
  }, [])

  const handleTableChange = useCallback((pagination, _, sorter) => {
    setRequest((prevRequest) => ({
      ...prevRequest,
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
      sortDir: sorter.order,
      sortCol: sorter.order ? sorter.field : prevRequest.sortCol,
    }))
  }, [])

  const columns = [
    {
      title: 'Nazwa',
      dataIndex: 'name',
      className: 'column-comment',
      render: (name, row) => (
        <Link to={'/admin' + createTicketLink(name, row.number)}>
          {name} [{row.number}]
        </Link>
      ),
      sorter: true,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Numer gry',
      dataIndex: 'number',
      className: 'column-comment',
      render: (text) => `${text}`,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Cena',
      dataIndex: 'price',
      className: 'column-comment',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (text) => `${formatPrice(text)}`,
    },
    {
      title: 'Szczegółowe informacje',
      dataIndex: 'comment',
      className: 'column-comment',
      render: (comment) => (
        <div dangerouslySetInnerHTML={createDangerousHTML(comment)} />
      ),
    },
    {
      title: 'Rozpoczęcie sprzedaży',
      className: 'column-comment',
      dataIndex: 'startDate',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (text) =>
        text ? new Date(text).toLocaleDateString() : 'Brak informacji',
    },
    {
      title: 'Zakończenie sprzedaży',
      className: 'column-comment',
      dataIndex: 'endDate',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (text) =>
        text ? new Date(text).toLocaleDateString() : 'Brak informacji',
    },
    {
      title: 'Data ostatniej aktualizacji',
      className: 'column-comment',
      dataIndex: 'lastUpdateDate',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (text) =>
        text ? new Date(text).toLocaleString() : 'Brak informacji',
    },
  ]

  if (!isTableLoading && error && !tickets)
    return (
      <Alert
        style={{
          maxWidth: '300px',
          textAlign: 'center',
          justifyContent: 'center',
          display: 'block',
          margin: 'auto',
          marginTop: '25px',
        }}
        description={error}
        type="error"
      />
    )

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Panel Admina - Ranking zdrapek Lotto - Jaka Zdrapka</title>
        <meta
          name="description"
          content={`Panel admina - lista wszystkich zdrapek.`}
        />
      </Helmet>
      <TicketFilters
        handleFilterChange={handleFilterChange}
        request={request}
      />
      {error && (
        <Col sm={6} offset={8} style={{ textAlign: 'center' }}>
          <Alert message="Błąd pobierania danych z serwera." type="error" />
        </Col>
      )}
      {tickets && (
        <StyledTable
          loading={isTableLoading}
          dataSource={tickets.data}
          columns={columns}
          rowKey={'number'}
          onChange={handleTableChange}
          pagination={{
            total: tickets.totalItems,
            current: request.currentPage,
          }}
          bordered
          scroll={{ x: 'max-content' }}
        />
      )}
    </>
  )
}

export default TicketsPage
