import { ConfigProvider, Layout, Typography } from 'antd'
import locale from 'antd/locale/pl_PL'
import 'dayjs/locale/pl'
import React, { useMemo } from 'react'
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import styled from 'styled-components'
import AdminLoginPage from './admin/LoginPage'
import TicketBasePage from './admin/TicketBasePage'
import AdminTicketDetailsPage from './admin/TicketDetailsPage'
import AdminTicketsPage from './admin/TicketsPage'
import MyHeader from './common/Header'
import ContactPage from './contact/ContactPage'
import FaqPage from './faq/FaqPage'
import PageNotFound from './PageNotFound'
import BecomeAPatronPage from './patron/BecomeAPatronPage'
import SettingsPage from './settings/SettingsPage'
import TicketDetailsPage from './tickets/TicketDetailsPage'
import TicketsPage from './tickets/TicketsPage'
import TicketStatisticsPage from './tickets/TicketStatisticsPage'

const { Header, Content, Footer } = Layout
const { Text } = Typography

const StyledContent = styled.div`
  background: #fff;
  padding: 10px;
  min-height: 380px;
  overflow: auto;
`

const App = () => {
  const currentYear = useMemo(() => new Date().getFullYear(), [])

  return (
    <Router>
      <ConfigProvider
        locale={locale}
        theme={{
          token: {
            fontSize: '16px',
          },
        }}
      >
        <Layout style={{ fontSize: '16px' }}>
          <Header style={{ padding: '0px 5px' }}>
            <MyHeader />
          </Header>
          <Content>
            <StyledContent>
              <Routes>
                <Route path="/" element={<TicketsPage />} />
                <Route path="/kontakt" element={<ContactPage />} />
                <Route path="/ustawienia" element={<SettingsPage />} />
                <Route path="/wspieram" element={<BecomeAPatronPage />} />
                <Route
                  path="/zdrapka/wykresy/:slug"
                  element={<TicketStatisticsPage />}
                />
                <Route path="/zdrapka/:slug" element={<TicketDetailsPage />} />
                <Route path="/faq" element={<FaqPage />} />

                <Route path="/admin/login" element={<AdminLoginPage />} />
                <Route
                  path="/admin/zdrapka/:slug"
                  element={<AdminTicketDetailsPage />}
                />
                <Route path="/admin/zdrapki" element={<AdminTicketsPage />} />
                <Route path="/admin/global" element={<TicketBasePage />} />
                <Route path="*" element={<PageNotFound />} />
              </Routes>
            </StyledContent>
          </Content>
          <Footer style={{ textAlign: 'center' }}>
            <Text strong={true}>JakaZdrapka.pl © {currentYear}</Text>
          </Footer>
        </Layout>
      </ConfigProvider>
    </Router>
  )
}

export default App
