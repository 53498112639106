import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { formatNumber } from '../../common/Helpers'

const TicketTopPrizesLeft = ({ diagramData, initialTopPrizes }) => {
  const yDomain = useMemo(
    () => [0, Math.ceil(initialTopPrizes * 1.1)],
    [initialTopPrizes],
  )

  const tickFormatter = useMemo(
    () => (tick) => new Date(tick).toLocaleDateString(),
    [],
  )
  const yTickFormatter = useMemo(() => (tick) => formatNumber(tick), [])
  const tooltipFormatter = useMemo(() => (value) => formatNumber(value), [])
  const labelFormatter = useMemo(
    () => (date) => 'Data: ' + new Date(date).toLocaleDateString(),
    [],
  )

  return (
    <ResponsiveContainer height={500}>
      <LineChart data={diagramData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" tickFormatter={tickFormatter} />
        <YAxis
          allowDecimals={false}
          dataKey="topPrizesLeft"
          width={105}
          domain={yDomain}
          tickFormatter={yTickFormatter}
        />
        <Tooltip formatter={tooltipFormatter} labelFormatter={labelFormatter} />
        <Legend />
        <Line
          type="monotone"
          dataKey="topPrizesLeft"
          stroke="#8884d8"
          activeDot={{ r: 8 }}
          name="Ilość głównych wygranych"
        />
        <ReferenceLine
          y={initialTopPrizes}
          stroke="green"
          strokeDasharray="3 3"
          label={{
            position: 'top',
            value:
              'Początkowa ilość głównych wygranych = ' +
              formatNumber(initialTopPrizes) +
              ' sztuk',
            fill: 'green',
            fontSize: 14,
          }}
        />
      </LineChart>
    </ResponsiveContainer>
  )
}

TicketTopPrizesLeft.propTypes = {
  diagramData: PropTypes.array.isRequired,
  initialTopPrizes: PropTypes.number.isRequired,
}

export default TicketTopPrizesLeft
