import { Typography } from 'antd'
import React from 'react'
const { Text } = Typography

const HowCalculationIsMade = () => (
  <Text>
    <Text style={{ display: 'block' }}>
      <Text strong>Szansa na nagrodę główną</Text> - jest to stosunek
      pozostałych nagród głównych do ilości losów znajdujących się w danej
      transzy wyrażony w %.
    </Text>
    <Text style={{ display: 'block', marginBottom: '20px' }}>
      <Text strong>Szacowany zwrot</Text> - jest to stosunek ewentualnej
      całkowitej wygranej do nakładu finansowego jaki trzeba włożyć by mieć
      teoretyczną pewność wygranej.
    </Text>
    <Text style={{ display: 'block' }}>
      <Text strong style={{ display: 'block' }}>
        Przykład:
      </Text>
      Za przykład posłuży nam zdrapka{' '}
      <Text strong>&quot;Mega Pensja 489&quot;</Text>, której dane na dzień 27
      stycznia 2020 są następujace:
    </Text>
    <Text style={{ display: 'block' }}>
      &emsp; - Początkowy nakład: 4 000 000 losów{' '}
    </Text>
    <Text style={{ display: 'block' }}>
      &emsp; - Początkowe nagrody główne: 2{' '}
    </Text>
    <Text style={{ display: 'block' }}>
      &emsp; - Wysokość nagrody głównej: 550 000 zł{' '}
    </Text>
    <Text style={{ display: 'block' }}>
      &emsp; - Na dzień wykonywania obliczeń dostępne było (dane pochodzą ze
      strony Lotto):{' '}
    </Text>
    <Text style={{ display: 'block' }}>
      &emsp; &emsp;0 z 8 nagród 20 000 zł,
    </Text>
    <Text style={{ display: 'block' }}>
      &emsp; &emsp;19 z 110 nagród 1 000 zł,
    </Text>
    <Text style={{ display: 'block' }}>
      &emsp; &emsp;85 z 1 000 nagród 500 zł,
    </Text>
    <Text style={{ display: 'block', marginBottom: '20px' }}>
      &emsp; &emsp;218 z 3 000 nagród 200 zł.
    </Text>
    <Text style={{ display: 'block' }}>
      Na podstawie tych informacji można obliczyć szacowaną pozostałą liczbę
      losów w sprzedaży. W tym celu należy dodać nadal dostępne znane wygrane
      niższych stopni (1 + 0 + 19 + 85 + 218 = 323) i podzielić je przez
      początkowe wygrane identycznych stopni (2 + 8 + 110 + 1 000 + 3 000 = 4
      120).
    </Text>
    <Text style={{ display: 'block' }}>
      Wynik to: 323 / 4120 * 100% = 7,83%.
    </Text>
    <Text style={{ display: 'block' }}>
      Następnie wynik tych obliczeń musimy pomnożyć przez początkowy nakład: 4
      000 000 * 7,83% = 313 592.
    </Text>
    <Text style={{ display: 'block', marginBottom: '20px' }}>
      W ten sposób otrzymujemy informację, że w sprzedaży powinno znajdować się
      jeszcze około 313 592 losów.
    </Text>
    <Text style={{ display: 'block' }}>
      Wiemy też, że początkowo były dostępne 2 nagrody główne, które zgodnie z{' '}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.lotto.pl/sites/default/files/pdf/zdrapki/regulamin-473.pdf"
      >
        regulaminem
      </a>{' '}
      dostępnym na stronie Lotto, dzielą wszystkie losy na 2 identyczne transze.
    </Text>
    <Text style={{ display: 'block', marginBottom: '20px' }}>
      Dzieląc zatem otrzymaną pozostałą liczbę losów na 2 otrzymujemy wynik 313
      592 / 2 = 156 796. I to jest szacowana liczba losów, które musielibyśmy
      kupić żeby mieć teoretyczną gwarancję wygrania nagrody głównej.
    </Text>
    <Text style={{ display: 'block' }}>
      Aby uzyskać wynik{' '}
      <Text strong>&quot;Szansy na nagrodę główną&quot; </Text>
      jak w Rankingu Zdrapek należy zaprezentować tę wartość w %. W tym celu
      dzielimy pozostałą ilość nagród głównych danej transzy przez przypadającą
      jej liczbę pozostałych losów dostępnych nadal w sprzedaży.
    </Text>
    <Text style={{ display: 'block', marginBottom: '20px' }}>
      Zatem: 1 / 156 796 * 100% = 0,0006377%. Wynik oznacza nasze szanse na
      główną nagrodę przy zakupie 1 losu.
    </Text>
    <Text style={{ display: 'block' }}>
      Natomiast aby wyliczyć <Text strong>&quot;Szacowany zwrot&quot;</Text>{' '}
      należy wyliczyć ile powinniśmy wygrać kupując wszystkie losy danej transzy
      zdrapek i podać procentowy stosunek kwoty wydanej do kwoty szacowanej
      wygranej.
    </Text>
    <Text style={{ display: 'block', marginBottom: '20px' }}>
      Bierzemy liczbę losów, które pozostały do wykupienia w danej transzy (156
      796 sztuk). Wiemy, że 1 los kosztuje 10 zł. Czyli na taką liczbę losów
      wydamy 1 567 960 zł.
    </Text>
    <Text style={{ display: 'block' }}>
      Regulamin zdrapki pozwala nam oszacować, że przy takiej ilości losów
      powinniśmy wygrać:
    </Text>
    <Text style={{ display: 'block' }}>
      &emsp; &emsp;4 wygrane o wartości 1 000 zł każda = 4 000 zł,
    </Text>
    <Text style={{ display: 'block' }}>
      &emsp; &emsp;39 wygranych o wartości 500 zł każda = 19 500 zł,
    </Text>
    <Text style={{ display: 'block' }}>
      &emsp; &emsp;117 wygranych o wartości 200 zł każda = 23 400 zł,
    </Text>
    <Text style={{ display: 'block' }}>
      &emsp; &emsp;1 254 wygranych o wartości 100 zł każda = 125 400 zł,
    </Text>
    <Text style={{ display: 'block' }}>
      &emsp; &emsp;3 135 wygranych o wartości 50 zł każda = 156 750 zł,
    </Text>
    <Text style={{ display: 'block' }}>
      &emsp; &emsp;4 703 wygranych o wartości 30 zł każda = 225 780 zł,
    </Text>
    <Text style={{ display: 'block' }}>
      &emsp; &emsp;11 289 wygranych o wartości 20 zł każda = 225 780 zł,
    </Text>
    <Text style={{ display: 'block', marginBottom: '20px' }}>
      &emsp; &emsp;20 383 wygranych o wartości 10 zł każda = 203 830 zł,
    </Text>
    <Text style={{ display: 'block' }}>
      Sumując szacowane wygrane otrzymujemy kwotę 899 750 zł. Do tej kwoty
      należy dodać kwotę głównej wygranej, obniżonej o wartość podatku od
      wygranej (10%): 899 750 zł + 550 000 zł * 0,9 = 1 394 750 zł.
    </Text>
    <Text style={{ display: 'block' }}>
      Następnie dzielimy kwotę szacowanej wygranej przez kwotę, którą
      wydalibyśmy na zdrapki i przygotowujemy wynik do wartości w %: 1 394 750 /
      1 567 960 * 100% - 100% =-11%.
    </Text>
    <Text style={{ display: 'block', marginBottom: '20px' }}>
      Wynik ten oznacza, że jeżeli chcielibyśmy wykupić wszystkie pozostałe
      sprzyjajace zdrapki dostępne w sprzedaży, to pomimo wygrania nagrody
      głównej stracilibyśmy na tych zakupach 11% wkładu przeznaczonego na
      wykupienie wszystkich zdrapek. Czyli bylibyśmy stratni 173 210‬ zł.
    </Text>
    <Text style={{ display: 'block' }}>
      <Text strong>Jak interpretować ten wynik?</Text>
    </Text>
    <Text style={{ display: 'block' }}>
      Zdrapka przy wprowadzeniu do sprzedaży ma wskaźnik zwrotu wynoszący od
      -50% do -40%. Dopiero jak ludzie zaczynają kupować zdrapki ten wskaźnik
      się polepsza. Im więcej zdrapek zostanie sprzedanych i im więcej nagród
      głównych będzie nadal dostępnych do wygrania tym bardziej opłaca się daną
      zdrapką kupować i tym samym wyższy będzie wskaźnik{' '}
      <Text strong>&quot;Szacowanego zwrotu&quot;</Text>. Jednocześnie jeżeli w
      zdrapce padną wszystkie nagrody główne to wskaźnik ten bardzo spada i jego
      wahania są już niewielkie do dnia zakończenia sprzedaży.
    </Text>
    <Text style={{ display: 'block' }}>
      Dlatego podczas kupowania zdrapek należy wybierać te, które na daną chwilę
      gwarantują nam najniższą stratę.
    </Text>
  </Text>
)

export default HowCalculationIsMade
