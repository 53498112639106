import { Typography } from 'antd'
import React from 'react'
import goracyPrzedzial from '../../../assets/goracy_przedzial.png'
import HotSectionTable from './HotSectionTable'
const { Text } = Typography

const WhatIsAHotSection = () => (
  <Text>
    <Text style={{ display: 'block', marginBottom: '10px' }}>
      Każda zdrapka posiada specyficzne pole z nadrukowanymi w nim liczbami.
      Całe pole składa się z 3 liczb oddzielonych myślnikiem. Pole to może
      wyglądać następująco:
    </Text>
    <img src={goracyPrzedzial} alt="Czym jest gorący przedział?" />
    <Text style={{ display: 'block', marginTop: '10px' }}>
      <Text strong>
        Opis poszczególnych części powyższego numeru jest następujący:{' '}
      </Text>
    </Text>
    <Text style={{ display: 'block' }}>
      <Text strong>- 0497 - </Text> jest to numer identyfikujący daną serię
      zdrapek. Wszystkie zdrapki z danej serii mają tę wartość identyczną. Z
      punktu widzenia gorących przedziałów ta wartość nie jest istotna.
    </Text>
    <Text style={{ display: 'block' }}>
      <Text strong>- 046607 - </Text> jest to numer określający paczkę zdrapek.
      <Text strong>
        {' '}
        To właśnie ta część nazywana jest gorącym przedziałem.{' '}
      </Text>{' '}
      Jeżeli napisane jest żeby poszukiwać zdrapek z przedziału np. 40 000 - 60
      000, to właśnie ten numer powinien wpasować się w gorący przedział.
    </Text>
    <Text style={{ display: 'block' }}>
      <Text strong>- 002 - </Text> jest to numer określający kolejność
      konkretnej zdrapki w danej paczce. Z punktu widzenia gorących przedziałów
      ta wartość nie jest istotna.
    </Text>
    <Text strong style={{ display: 'block', marginTop: '20px' }}>
      Jak eliminować &quot;zimne&quot; przedziały?{' '}
    </Text>
    <Text style={{ display: 'block' }}>
      Do opisania metody eliminacji zimnych przedziałów posłużę się zdrapką{' '}
      <Text strong>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.lotto.pl/zdrapki/katalog-zdrapek/premia-,1199"
        >
          &quot;Premia 569&quot;
        </a>
        .
      </Text>
    </Text>
    <Text style={{ display: 'block' }}>
      Zgodnie z{' '}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.lotto.pl/storage/files/2021/2/2/b031be1f-1eb7-4372-9364-3da0b37d61d7/Regulamin_569.pdf"
      >
        regulaminem
      </a>{' '}
      sprzedaży zdrapka <Text strong>&quot;Premia 569&quot;</Text> została
      podzielona na 6 równch transz. Wiemy, że zdrapka ta została wydrukowana w
      nakładzie 6 000 000 losów. Wiemy również, że zdrapki w cenie 5 zł są
      pakowane w paczki po 50 sztuk. Liczymy zatem ile paczek losów zostało
      wydrukowanych: 6 000 000 / 50 = 120 000 paczek losów.
    </Text>
    <Text style={{ display: 'block' }}>
      Następnie liczbę wszystkich paczek losów dzielimy przez liczbę
      wspomnianych transz: 120 000 / 6 = 20 000. Oznacza to, że każda transza
      liczy 20 000 paczek losów. W przypadku tej zdrapki, w każdej transzy losów
      znajduje się dokładnie 1 nagroda główna.
    </Text>
    <Text style={{ display: 'block' }}>
      W większości przypadków paczki zdrapek są drukowane jedna po drugiej, a co
      za tym idzie również i transze losów są w ten sposób drukowane. Pozwala to
      nam oszacować, że w każdym z poniższych przedziałów powinna znajdować się
      dokładnie 1 nagroda główna. Na stronie zdrapki{' '}
      <Text strong>&quot;Premia 569&quot;</Text>,{' '}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.lotto.pl/zdrapki/katalog-zdrapek/premia-,1199"
      >
        tutaj
      </a>
      , dostępne są skany wszystkich nagród głównych, które zostały w tej
      zdrapce wydrapane. Przeglądając skany mamy możliwość zobaczenia numery
      paczek wygrywających losów. Przyporządkowując te numery do transz można
      eliminować te zakresy, które są już &quot;spalone&quot; i kupowac zdrapki
      tylko z tych transz, które powinny posiadać jeszcze nagrodę główną.
      Przedział w transzy, który powinien posiadać nagrodę główną jest nazywany{' '}
      <Text strong>goracym przedziałem</Text>.
      <Text strong style={{ display: 'block', marginTop: '20px' }}>
        Uwagi:
      </Text>
      <Text style={{ display: 'block' }}>
        Zdarza się, że ta sama zdrapka jest wydrukowana w kilku kolorach. W
        takim przypadku między jednym kolorem a drugim (np. między niebieskim, a
        żółtym) powstaje pewna przerwa. Np. zdrapki niebieskie mogą mieć numery
        od 000 001 do 080 000, natomiast zdrapki żółte od 120 000 do 200 000.
        Widać, że między 080 000 a 120 000 powstaje przerwa. Jest to zjawisko
        normalne, i aby je wykryć trzeba obserwować numery zdrapek w sprzedaży.
        Dzięki dostępowi do takich numerów, np. dzięki osobom chwalącym się
        wygranymi na FB, można dynamicznie określać gorące i zimne przedziały.
      </Text>
      <Text style={{ display: 'block' }}>
        Zdarza się również, że zdrapki zostają wydrukowane źle. Np. kolory nie
        odpowiadają wymaganiom. Wtedy pewna pula zdrapek jest drukowana
        ponownie, z kolejnymi numerami paczek. Może się okazać, że np. zdrapka,
        która posiada 120 000 paczek losów, została wydrukowana z numerem 123
        567. Oznacza to nic innego jak to, że część zdrapek została
        przedrukowana właśnie z uwagi na wydruk niespełniający wymagań. Przy
        określaniu gorących i zimnych przedziałów, należy również pilnować
        takich sytuacji.
      </Text>
    </Text>
    <HotSectionTable />
  </Text>
)

export default WhatIsAHotSection
