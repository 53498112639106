import { Collapse, Typography } from 'antd'
import React, { useMemo } from 'react'
import { Helmet } from 'react-helmet-async'
import styled from 'styled-components'
import HowCalculationIsMade from './FaqQuestions/HowCalculationIsMade'
import HowTicketsArePacked from './FaqQuestions/HowTicketsArePacked'
import WhatAreControlLetters from './FaqQuestions/WhatAreControlLetters'
import WhatIsAHotSection from './FaqQuestions/WhatIsAHotSection'
import WhatIsATicketPortion from './FaqQuestions/WhatIsATicketPortion'
import WhereItIsEasierToWin from './FaqQuestions/WhereItIsEasierToWin'

const { Title } = Typography

const StyledTitle = styled(Title)`
  font-size: 20px;
`

const FaqPage = () => {
  const items = useMemo(
    () => [
      {
        key: '1',
        label: 'W jaki sposób wyliczane są dane?',
        children: <HowCalculationIsMade />,
      },
      {
        key: '2',
        label: 'Czym jest transza losów?',
        children: <WhatIsATicketPortion />,
      },
      {
        key: '3',
        label: 'Czym jest gorący przedział?',
        children: <WhatIsAHotSection />,
      },
      {
        key: '4',
        label: 'Czym są literki kontrolne?',
        children: <WhatAreControlLetters />,
      },
      {
        key: '5',
        label: 'Po ile sztuk pakowane są zdrapki w kolekturach?',
        children: <HowTicketsArePacked />,
      },
      {
        key: '6',
        label: 'W których zdrapkach najłatwiej coś wygrać?',
        children: <WhereItIsEasierToWin />,
      },
    ],
    [],
  )

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Najczęściej zadawane pytania - Jaka Zdrapka</title>
        <meta
          name="description"
          content="Co to jest transza zdrapek? Czym jest gorący przedział w zdrapkach? Co oznaczają literki kontrolne na zdrapkach?"
        />
      </Helmet>
      <StyledTitle>FAQ - czyli najczęściej zadawane pytania</StyledTitle>
      <Collapse accordion items={items} />
    </>
  )
}

export default FaqPage
