import { FontSizeOutlined, LayoutOutlined } from '@ant-design/icons'
import { Radio, Switch, Typography } from 'antd'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet-async'

const { Title } = Typography

const FaqPage = () => {
  const [defaultFontSize, setDefaultFontSize] = useState('default')
  const [defaultThemeColor, setDefaultThemeColor] = useState('dark')

  const handleDefaultFontSizeChange = (e) => {
    setDefaultFontSize(e.target.value)
  }

  const handleThemeColorChange = (e) => {
    if (defaultThemeColor === 'dark') setDefaultThemeColor('light')
    else setDefaultThemeColor('dark')
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Ustawienia - Jaka Zdrapka</title>
        <meta
          name="description"
          content={`Zmień swoje domyślne ustawienia na stronie JakaZdrapka.pl`}
        />
      </Helmet>
      <Title style={{ fontSize: '20px' }}>
        Dostosuj widok strony JakaZdrapka.pl do siebie
      </Title>
      <Title style={{ fontSize: '18px' }}>
        <FontSizeOutlined /> Rozmiar domyślnej czcionki:
      </Title>
      <Radio.Group
        value={defaultFontSize}
        onChange={handleDefaultFontSizeChange}
        style={{ display: 'block', marginBottom: '20px' }}
      >
        <Radio.Button value="small" style={{ fontSize: '14px' }}>
          Mała
        </Radio.Button>
        <Radio.Button value="default" style={{ fontSize: '16px' }}>
          Domyślna
        </Radio.Button>
        <Radio.Button value="large" style={{ fontSize: '18px' }}>
          Duża
        </Radio.Button>
      </Radio.Group>
      <Title style={{ fontSize: '18px' }}>
        <LayoutOutlined />
        Motyw strony:
      </Title>
      <Switch
        checked={defaultThemeColor === 'dark'}
        onChange={handleThemeColorChange}
        checkedChildren="Ciemny"
        unCheckedChildren="Jasny"
        style={{ display: 'block', marginBottom: '20px' }}
      />
    </>
  )
}

export default FaqPage
