import { Typography } from 'antd'
import React from 'react'
import literki from '../../../assets/3_rozne_literki_kontrolne.png'
const { Text } = Typography

const WhatAreControlLetters = () => (
  <Text>
    <Text style={{ display: 'block' }}>
      Literki kontrolne to trzy małe literki znajdujące się pod warstwą
      ścieralną zdrapki. Na ich podstawie można określić 2 rzeczy:
    </Text>
    <Text style={{ display: 'block' }}>
      <Text strong>- 3 różne literki kontrolne - </Text>
      zdrapka może być wygrana, ale nie musi. <Text strong>3 różne </Text>{' '}
      literki pojawiają się przy wysokich wygranych, lub kiedy zdrapka jest
      pusta. Są oczywiście od tej zasady wyjątki i niekiedy nawet wygrana 25 zł
      będzie posiadała 3 różne literki kontrolne.{' '}
      <Text strong>
        Każda wygrana powyżej 999 zł ma 3 różne literki kontrolne.{' '}
      </Text>
    </Text>
    <Text style={{ display: 'block', marginBottom: '20px' }}>
      <Text strong>- 3 identyczne literki kontrolne - </Text> zdrapka na pewno
      jest zdrapką wygraną. 3 identyczne literki kontrolne oznaczają wygraną
      niskiego stopnia (maksymalnie 999 zł).
    </Text>
    Wielu ludzi drapie zdrapki szukając tylko literek kontrolnych, widząc 3
    różne literki kontrolne wyrzucają zdrapkę do kosza.{' '}
    <Text strong>To ogromny błąd! </Text>Zdrapki z różnymi literkami kontrolnymi
    sprawdzaj bardzo dokładnie, bo mogą zawierać wygraną wysokiego stopnia.{' '}
    <Text strong>
      {' '}
      W razie wątpliwości zanieś zdrapkę do kolektury i poproś o jej sprawdzenie
      przez kolektora na terminalu Lotto.
    </Text>
    <Text style={{ display: 'block', marginBottom: '20px' }}>
      Poniżej przykład oznaczenia literek kontrolnych na zdrapce{' '}
      <Text strong>&quot;Lucky 7&quot;</Text>. Zaznaczone zostały 3 różne
      literki [CFL], widać jednak, że <Text strong>zdrapka jest wygrana</Text>!
      Liczba 25 oznacza wygraną <Text strong>50 000 zł</Text>!
    </Text>
    <img
      style={{ maxWidth: '95%' }}
      src={literki}
      alt="Czym są literki kontrolne?"
    />
  </Text>
)

export default WhatAreControlLetters
