import { Typography } from 'antd'
import React from 'react'
const { Text } = Typography

const WhereItIsEasierToWin = () => (
  <Text>
    <Text style={{ display: 'block', marginBottom: '10px' }}>
      W wielkim skrócie: im droższa zdrapka, tym łatwiej coś trafić.
    </Text>
    <Text style={{ display: 'block' }}>Przeważnie w zdrapkach:</Text>
    <Text style={{ display: 'block' }}>- za 1 zł - wygrywa co 5. zdrapka,</Text>
    <Text style={{ display: 'block' }}>
      - za 2, 3, 5, 10 zł - wygrywa co 4. zdrapka,
    </Text>
    <Text style={{ display: 'block', marginBottom: '10px' }}>
      - za 20, 25, 30 zł - wygrywa co 3. zdrapka.
    </Text>
    <Text style={{ display: 'block' }}>
      Należy jednak spojrzeć także na to, że za 30 zł możemy kupić np. 30
      zdrapek w cenie 1 zł. W takim wypadku szanse na to, że w tych 30 zdrapkach
      coś się trafi są wyższe niż to, że trafi nam się coś w jednej zdrapce za
      30 zł.
    </Text>
    <Text style={{ display: 'block' }}>
      Oczywiście trzeba wziąć pod uwagę też to, że zdrapki w cenie 1 zł oferują
      wygrane rzędu tysięcy/kilkudziesięciu tysięcy złotych, natomiast zdrapki w
      cenie 30 zł oferują wygraną rzędu kilkuset tysięcy złotych, bądź kilku
      milionów złotych.
    </Text>
    <Text style={{ display: 'block' }}>
      Każdy osobiście powinien określić to jaka wygrana go interesuje i na tej
      podstawie dobrać zdrapki dla siebie.
    </Text>
  </Text>
)

export default WhereItIsEasierToWin
