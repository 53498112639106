import wretch from 'wretch'
import QueryStringAddon from 'wretch/addons/queryString'
import { prepareRequest } from './api'

const api = wretch().addon(QueryStringAddon).url(process.env.REACT_APP_API_URL)

const TicketsApi = {
  async getTicketsFromApi(request) {
    const modifiedRequest = prepareRequest(request)
    const result = await api
      .url('/web/tickets')
      .query(modifiedRequest)
      .get()
      .json()
    return result
  },

  async getTicketsNames() {
    return await api.url('/web/names').get().json()
  },

  async getTicketByNumber(ticketNumber) {
    return await api.url(`/web/${ticketNumber}`).get().json()
  },

  async getPrizePoolHistory(ticketNumber, datesRequest) {
    return await api
      .url(`/web/historical/${ticketNumber}`)
      .query(prepareRequest(datesRequest))
      .get()
      .json()
  },

  async getLastUpdateDate() {
    return await api.url(`/web/lastupdatedate`).get().json()
  },
}

export default TicketsApi
