import {
  Alert,
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Spin,
  Typography,
} from 'antd'
import dayjs from 'dayjs'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import AdminApi from '../../../api/adminApi'

const { Title } = Typography

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
}

const BasicData = () => {
  const [ticket, setTicket] = useState(null)
  const [response, setResponse] = useState(null)
  const [formIsSaving, setFormIsSaving] = useState(false)
  const ticketGameNumber = window.location.href.substr(
    window.location.href.length - 3,
  )

  const getTicketDetails = useCallback(async () => {
    const data = await AdminApi.getTicketByNumber(ticketGameNumber)
    setTicket(data)
  }, [ticketGameNumber])

  useEffect(() => {
    getTicketDetails()
  }, [getTicketDetails])

  const quillModules = useMemo(
    () => ({
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        [{ size: ['small', false, 'large', 'huge'] }],
        [{ color: [] }],
        [{ font: [] }],
        [{ align: [] }],
        ['clean'],
      ],
    }),
    [],
  )

  const handleInputChange = (e) => {
    const { id, value } = e.target
    setTicket((prevTicket) => ({ ...prevTicket, [id]: value }))
  }

  const handleNumberChange = (name, value) => {
    setTicket((prevTicket) => ({ ...prevTicket, [name]: value }))
  }

  const handleDateChange = (name, value) => {
    setTicket((prevTicket) => ({
      ...prevTicket,
      [name]: value ? value.format('YYYY-MM-DD') : null,
    }))
  }

  const handleQuillChange = (name, value) => {
    setTicket((prevTicket) => ({ ...prevTicket, [name]: value }))
  }

  const handleFormSubmit = async () => {
    setResponse(null)
    setFormIsSaving(true)
    try {
      await AdminApi.updateTicketInfo(ticket)
      setResponse({ success: true })
      await getTicketDetails()
    } catch (e) {
      setResponse({ success: false, message: e.json.message })
    }
    setFormIsSaving(false)
  }

  if (!ticket) {
    return (
      <Title
        style={{ fontSize: '18px', textAlign: 'center', marginBottom: '15px' }}
      >
        Pobieranie informacji o zdrapkach...
        <Spin size="large" />
      </Title>
    )
  }

  return (
    <Form {...formItemLayout}>
      <Form.Item label="Id">
        <Input id="id" value={ticket.id} disabled />
      </Form.Item>
      <Form.Item label="Nazwa">
        <Input id="name" value={ticket.name} onChange={handleInputChange} />
      </Form.Item>
      <Form.Item label="Numer gry">
        <InputNumber
          min={100}
          max={999}
          style={{ width: 160 }}
          value={ticket.number}
          onChange={(value) => handleNumberChange('number', value)}
        />
      </Form.Item>
      <Form.Item label="Cena">
        <InputNumber
          min={1}
          max={50}
          style={{ width: 160 }}
          value={ticket.price}
          onChange={(value) => handleNumberChange('price', value)}
        />
      </Form.Item>
      <Form.Item label="Link do zdjęcia zdrapki:">
        <Input
          id="exampleImageUrl"
          value={ticket.exampleImageUrl}
          onChange={handleInputChange}
        />
      </Form.Item>
      <Form.Item label="Link do logo zdrapki:">
        <Input
          id="ticketLogoUrl"
          value={ticket.ticketLogoUrl}
          onChange={handleInputChange}
        />
      </Form.Item>
      <Form.Item label="Początkowy nakład">
        <InputNumber
          min={1000000}
          max={50000000}
          style={{ width: 160 }}
          value={ticket.initialQuantity}
          onChange={(value) => handleNumberChange('initialQuantity', value)}
        />
      </Form.Item>
      <Form.Item label="Komentarz">
        <ReactQuill
          value={ticket.comment}
          onChange={(value) => handleQuillChange('comment', value)}
          modules={quillModules}
        />
      </Form.Item>
      <Form.Item label="Litery kontrolne">
        <ReactQuill
          value={ticket.controlLetters}
          onChange={(value) => handleQuillChange('controlLetters', value)}
          modules={quillModules}
        />
      </Form.Item>
      <Form.Item label="Data rozpoczęcia sprzedaży">
        <DatePicker
          allowClear={false}
          value={ticket.startDate ? dayjs(ticket.startDate) : null}
          onChange={(value) => handleDateChange('startDate', value)}
        />
      </Form.Item>
      <Form.Item label="Data zakończenia sprzedaży">
        <DatePicker
          allowClear={false}
          value={ticket.endDate ? dayjs(ticket.endDate) : null}
          onChange={(value) => handleDateChange('endDate', value)}
        />
      </Form.Item>
      <Form.Item label="Data ostatniej aktualizacji">
        <DatePicker
          allowClear={false}
          showTime
          value={ticket.lastUpdateDate ? dayjs(ticket.lastUpdateDate) : null}
          onChange={(value) => handleDateChange('lastUpdateDate', value)}
        />
      </Form.Item>
      <Form.Item label="External ID">
        <Input
          id="externalId"
          value={ticket.externalId}
          onChange={handleInputChange}
        />
      </Form.Item>
      <Form.Item label="Liczba transz">
        <InputNumber
          min={1}
          max={50000}
          style={{ width: 160 }}
          value={ticket.ticketPortions}
          onChange={(value) => handleNumberChange('ticketPortions', value)}
        />
      </Form.Item>
      <Form.Item label="Liczba głównych w transzy">
        <InputNumber
          min={0}
          max={50000}
          style={{ width: 160 }}
          value={ticket.availableTopPrizesInPortion}
          onChange={(value) =>
            handleNumberChange('availableTopPrizesInPortion', value)
          }
        />
        <Button
          type="primary"
          loading={formIsSaving}
          onClick={handleFormSubmit}
          style={{ display: 'block', marginTop: '15px' }}
        >
          Zapisz zmiany
        </Button>
        {response && (
          <Alert
            message={
              response.success ? 'Zaktualizowano pomyślnie' : 'Wystąpił błąd!'
            }
            description={response.message}
            type={response.success ? 'success' : 'error'}
            closable
          />
        )}
      </Form.Item>
    </Form>
  )
}

export default BasicData
