import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

const TicketChanceForWinning1stDegreePrize = ({
  diagramData,
  biggestChancesFor1stDegreePrize,
}) => {
  const yDomain = useMemo(
    () => [
      0,
      Math.round(
        (biggestChancesFor1stDegreePrize * 1.1 + 0.0005 + Number.EPSILON) *
          10000,
      ) / 10000,
    ],
    [biggestChancesFor1stDegreePrize],
  )

  const tickFormatter = useMemo(
    () => (tick) => new Date(tick).toLocaleDateString(),
    [],
  )
  const yTickFormatter = useMemo(() => (tick) => `${tick}%`, [])
  const tooltipFormatter = useMemo(() => (value) => `${value}%`, [])
  const labelFormatter = useMemo(
    () => (date) => 'Data: ' + new Date(date).toLocaleDateString(),
    [],
  )

  return (
    <ResponsiveContainer height={500}>
      <LineChart data={diagramData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" tickFormatter={tickFormatter} />
        <YAxis
          dataKey="chanceForWinning1stDegreePrize"
          width={105}
          domain={yDomain}
          tickFormatter={yTickFormatter}
        />
        <Tooltip formatter={tooltipFormatter} labelFormatter={labelFormatter} />
        <Legend />
        <Line
          type="monotone"
          dataKey="chanceForWinning1stDegreePrize"
          stroke="#8884d8"
          activeDot={{ r: 8 }}
          name="Szansa na wygranie nagrody 1. stopnia"
        />
      </LineChart>
    </ResponsiveContainer>
  )
}

TicketChanceForWinning1stDegreePrize.propTypes = {
  diagramData: PropTypes.array.isRequired,
  biggestChancesFor1stDegreePrize: PropTypes.number.isRequired,
}

export default TicketChanceForWinning1stDegreePrize
