import { Table } from 'antd'
import React from 'react'
import styled from 'styled-components'

const StyledTable = styled(Table)`
  th.column-comment,
  td.column-comment {
    text-align: center !important;
  }
`

const dataSource = [
  {
    key: '1',
    price: '1 zł',
    amount: '200 sztuk',
  },
  {
    key: '2',
    price: '2 zł',
    amount: '100 sztuk',
  },
  {
    key: '3',
    price: '3 zł',
    amount: '100 sztuk',
  },
  {
    key: '5',
    price: '5 zł',
    amount: '50 sztuk',
  },
  {
    key: '10',
    price: '10 zł',
    amount: '25 sztuk',
  },
  {
    key: '20',
    price: '20 zł',
    amount: '15 sztuk',
  },
  {
    key: '25',
    price: '25 zł',
    amount: '10 sztuk',
  },
  {
    key: '30',
    price: '30 zł',
    amount: '10 sztuk',
  },
]

const columns = [
  {
    title: 'Cena zdrapki',
    dataIndex: 'price',
    key: 'price',
    className: 'column-comment',
  },
  {
    title: 'Ilość zdrapek w paczce',
    dataIndex: 'amount',
    key: 'amount',
    className: 'column-comment',
  },
]

const HowTicketsArePackedTable = () => (
  <StyledTable
    dataSource={dataSource}
    columns={columns}
    pagination={false}
    style={{ display: 'block', marginTop: '10px', width: '300px' }}
  />
)

export default HowTicketsArePackedTable
