import { Button, Card, Col, Divider, Menu, Row, Spin, Typography } from 'antd'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { NavLink } from 'react-router-dom'
import TicketsApi from '../../api/ticketApi'
import {
  createDiagramLink,
  createTicketLinkInDetails,
  formatNumber,
  formatPrice,
  prepareTicketComment,
  renderColoredValue,
  restOfTicketsToPolishString,
} from '../common/Helpers'

const { Title, Text } = Typography

const TicketDetailsPage = () => {
  const [ticket, setTicket] = useState(null)
  const [ticketNames, setTicketsNames] = useState(null)
  const [openedKeys, setOpenedKeys] = useState([])
  const ticketGameNumber = window.location.href.substr(
    window.location.href.length - 3,
  )

  const getTicketDetails = useCallback(async () => {
    window.scrollTo(0, 0)
    const result = await TicketsApi.getTicketByNumber(ticketGameNumber)
    setTicket(result)
  }, [ticketGameNumber])

  useEffect(() => {
    getTicketDetails()
  }, [getTicketDetails])

  const getTicketsNames = useCallback(async () => {
    setTicketsNames(await TicketsApi.getTicketsNames())
  }, [])

  useEffect(() => {
    getTicketsNames()
  }, [getTicketsNames])

  const renderTicketDetails = useMemo(() => {
    if (!ticket) return null

    return (
      <div
        style={{
          margin: 10.5,
          textAlign: 'center',
        }}
      >
        <Title
          style={{
            fontSize: '22px',
          }}
        >
          {ticket.name + ` [${ticketGameNumber}]`}
        </Title>
        <Card
          styles={{ header: { padding: '5px' }, body: { padding: '5px' } }}
          bordered={false}
          title={
            <img
              style={{
                objectFit: 'contain',
                maxHeight: '500px',
                width: '100%',
              }}
              src={ticket.exampleImageUrl}
              alt="Logo zdrapki"
              target="_self"
            />
          }
        >
          <Text strong>
            {restOfTicketsToPolishString(ticket.topPrizesLeft)}{' '}
            {formatNumber(ticket.topPrizesLeft)} z{' '}
            {formatNumber(ticket.topPrizesAtStart)} nagród głównych
          </Text>
          <Divider />
          <Text>Cena: {formatPrice(ticket.price)}</Text>
          <br />
          <Text>Główna wygrana: {formatPrice(ticket.biggestPrizeValue)}</Text>
          <br />
          <Text>
            Szansa na główną: {ticket.chanceForWinning1stDegreePrize}%
          </Text>
          <br />
          <Text>
            Szacowany zwrot: {renderColoredValue(ticket.investmentReturn)}
          </Text>
          <Divider />
          <Text>
            Pozostało {formatNumber(ticket.actualAmount)} losów (
            {((ticket.actualAmount * 100) / ticket.initialAmount).toFixed(2)}%)
          </Text>
          <Divider />
          <Text>
            <div
              style={{
                textAlign: 'center',
                justifyContent: 'center',
              }}
              dangerouslySetInnerHTML={prepareTicketComment(
                ticket.comment,
                ticket.startDate,
                ticket.endDate,
                ticket.topPrizesLeft,
                ticket.topPrizesAtStart,
                ticket.winningTicketScans,
              )}
            />
          </Text>
          <Divider />
          <div
            style={{
              textAlign: 'center',
              justifyContent: 'center',
            }}
          >
            <Text
              style={{
                textAlign: 'center',
                justifyContent: 'center',
              }}
              strong
            >
              Litery kontrolne:
            </Text>
          </div>
          <Text>
            <div
              style={{
                textAlign: 'center',
                justifyContent: 'center',
              }}
              dangerouslySetInnerHTML={{ __html: ticket.controlLetters }}
            />
          </Text>
          <NavLink
            to={createDiagramLink(ticket.name, ticket.number)}
            style={{ marginTop: '20px', display: 'inline-block' }}
          >
            <Button type="primary">Przejdź do danych historycznych</Button>
          </NavLink>
        </Card>
      </div>
    )
  }, [ticket, ticketGameNumber])

  const collapseMenu = useCallback(() => {
    setOpenedKeys((prevKeys) => (prevKeys.length === 1 ? [] : ['tickets']))
  }, [])

  const getMenuItems = useMemo(() => {
    if (!ticketNames) return []

    const menuItems = ticketNames.map((ticket) => ({
      key: ticket.number,
      label: (
        <NavLink
          to={`/zdrapka/${createTicketLinkInDetails(ticket.name, ticket.number)}`}
          key={ticket.number}
          onClick={collapseMenu}
        >
          {ticket.name} [{ticket.number}]
        </NavLink>
      ),
    }))

    return [
      {
        label: `${ticket?.name} [${ticket?.number}]`,
        key: 'tickets',
        children: menuItems,
      },
    ]
  }, [ticketNames, collapseMenu, ticket])

  if (!ticket || !ticketNames)
    return (
      <>
        <Title
          strong
          style={{
            fontSize: '18px',
            textAlign: 'center',
            display: 'block',
            marginBottom: '15px',
          }}
        >
          Pobieranie informacji o szczegółach zdrapki...
          <br />
          <Spin size="large" />
        </Title>
      </>
    )

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{ticket.name} - szczegóły zdrapki - Jaka Zdrapka</title>
        <meta
          name="description"
          content={`Jaka jest pula na wygrane i jakie są szanse na wygranie nagrody głównej w zdrapce  ${ticket.name}? `}
        />
      </Helmet>
      <Row>
        <Col flex="300px">
          <Menu
            mode="inline"
            selectedKeys={[ticketGameNumber]}
            style={{ width: 256 }}
            openKeys={openedKeys}
            onOpenChange={collapseMenu}
            items={getMenuItems}
            title={ticket?.name}
          />
        </Col>
        <Col
          flex="auto"
          style={{
            margin: 10.5,
            textAlign: 'center',
          }}
        >
          {renderTicketDetails}
        </Col>
      </Row>
    </>
  )
}

export default TicketDetailsPage
