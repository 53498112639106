import { Table } from 'antd'
import React from 'react'
import styled from 'styled-components'

const StyledTable = styled(Table)`
  th.column-comment,
  td.column-comment {
    text-align: center !important;
  }
`

const dataSource = [
  {
    key: '1',
    przedzial: 'I - 000 001 - 020 000',
    wygranaPaczka: '004 202',
  },
  {
    key: '2',
    przedzial: 'II - 020 001 - 040 000',
    wygranaPaczka: '022 867',
  },
  {
    key: '3',
    przedzial: 'III - 040 001 - 060 000',
    wygranaPaczka: '045 055',
  },
  {
    key: '4',
    przedzial: 'IV - 060 001 - 080 000',
    wygranaPaczka: '061 036',
  },
  {
    key: '5',
    przedzial: 'V - 080 001 - 100 000',
    wygranaPaczka: '081 396',
  },
  {
    key: '6',
    przedzial: 'VI - 100 001 - 120 000',
    wygranaPaczka: '115 471',
  },
]

const columns = [
  {
    title: 'Numer transzy i przypadające jej przedziały',
    dataIndex: 'przedzial',
    key: 'przedzial',
    className: 'column-comment',
  },
  {
    title: 'Numer wygrywającej paczki',
    dataIndex: 'wygranaPaczka',
    key: 'wygranaPaczka',
    className: 'column-comment',
  },
]

const HotSectionTable = () => (
  <StyledTable
    dataSource={dataSource}
    columns={columns}
    pagination={false}
    style={{
      display: 'block',
      marginTop: '10px',
      width: '400px',
    }}
  />
)

export default HotSectionTable
